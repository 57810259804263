import { findOperation, sortHeaders } from '@readme/server-shared/metrics-oas'; // eslint-disable-line readme-internal/no-restricted-imports
import { EXPLORER_ENABLED, METRICS_ENABLED } from 'oas/extensions';
import { Operation } from 'oas/operation';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useCallback, useState } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { ProjectContext, UserContext, VersionContext } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import useRdmdOpts from '@core/hooks/useRdmdOpts/legacy';
import useRoute from '@core/hooks/useRoute';
import useSentry from '@core/hooks/useSentry';
import useUserPermissions from '@core/hooks/useUserPermissions';
import { useProjectStore, useReferenceStore, InitializeReferenceStore } from '@core/store';

import UserAvatar from '@routes/Reference/Realtime/components/UserAvatar';

import Callbacks from '@ui/API/Callbacks';
import Header from '@ui/API/Header';
import Response from '@ui/API/Response';
import ResponseSchemaPicker from '@ui/API/ResponseSchemaPicker';
import { createSchema } from '@ui/API/Schema';
import SectionHeader from '@ui/API/SectionHeader';
import Button from '@ui/Button';
import Footer from '@ui/DocFooter';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import LanguagePicker from '@ui/LanguagePicker';
import QuickNav from '@ui/QuickNav';
import transformSidebarDataForQuickNav from '@ui/QuickNav/inputDataTransformers/transformSidebarDataForQuickNav';
import RDMD, { TOC } from '@ui/RDMD';
import Sidebar from '@ui/Sidebar';

import AuthContainer from './components/AuthContainer';
import ErrorBoundary from './components/ErrorBoundary';
import GraphContainer from './components/GraphContainer';
import Playground, { PlaygroundSection } from './components/Playground';
import RecipeContainer from './components/RecipeContainer';
import RequestContainer from './components/RequestContainer';
import ServerContainer from './components/ServerContainer';
import TableContainer from './components/TableContainer';
import { EphemeralHARContext, MockHarContext } from './context/HARContext';
import useAuthInputs from './hooks/useAuthInputs';
import useOas from './hooks/useOas';
import Realtime from './Realtime';
import classes from './style.module.scss';
import './style.scss';
import PreviewBanner from './TestBed/PreviewBanner';
import vscodelogo from './vscode.svg';

export default function Reference(props) {
  const { project } = useContext(ProjectContext);
  const [
    allowApiExplorerJsonEditor,
    alwaysUseDefaults,
    defaultExpandResponseExample,
    defaultExpandResponseSchema,
    showTableOfContents,
    showMetricsInReference,
    oauthUrl,
  ] = useProjectStore(s => [
    s.data.reference.json_editor === 'enabled',
    s.data.reference.defaults === 'always_use',
    s.data.reference.response_examples === 'expanded',
    s.data.reference.response_schemas === 'expanded',
    s.data.appearance.table_of_contents === 'enabled',
    s.data.reference.request_history === 'enabled',
    s.data.custom_login.login_url,
  ]);
  const [selectedHar, setSelectedHar, servers] = useReferenceStore(s => [
    s.selectedHar,
    s.updateSelectedHar,
    s.form.schemaEditor.data.server,
  ]);
  const { version } = useContext(VersionContext);
  const { clearEphemeralHAR, ephemeralHAR, setEphemeralHAR } = useContext(EphemeralHARContext);

  const { isPlayground } = useEnvInfo();

  const [showLogs, setShowLogs] = useState(false);

  const onError = useSentry('reference');

  const sidebar = props?.sidebars?.refs;

  /**
   * @note
   * In order to get the playground loading from our
   * JSON specs instead of the route we need to mock
   * out the `useRouteData` hook to avoid overwriting
   * it with the async return data from the controller.
   */
  const makeState = isPlayground ? props.useTestBedState : useRoute;
  const setupMeta = useCallback(
    meta => {
      // We have to add the sidebar to the metadata so we can display the correct subnav dropdown on mobile
      meta.sidebar = sidebar;
    },
    [sidebar],
  );
  const {
    state: { doc, oauth, oasDefinition, oasPublicUrl, ...state } = {},
    loading,
    redirected,
    redirectedPath,
  } = makeState(props, setupMeta);

  const rdmdOpts = useRdmdOpts(props, state, { copyButtons: true });

  const isEndpoint = doc?.type === 'endpoint';
  const isWebhook = doc?.type === 'webhook';

  const { oas, operation, dereferencingError, isLoading: isOasDereferencing } = useOas(oasDefinition, doc);

  const location = useLocation();
  const { path } = useRouteMatch();

  // Is My Requests page enabled (Dev Dash in Hub)
  const myRequestsEnabled = useMemo(() => {
    return !!sidebar?.[0]?.pages?.find(({ slug }) => slug === 'my-requests') || false;
  }, [sidebar]);

  const handleSelectLog = useCallback(
    ({ id, response }) => {
      // Always clear the har shown in the playground when a log is de-selected
      if (id == null) {
        setEphemeralHAR(false);
      }

      if (id == null || response?.log == null) {
        setSelectedHar(null);
        return;
      } else if (isOasDereferencing) {
        return;
      }

      const { log, sharedMetric } = response;
      const { logOperation } = findOperation(log, [oas]);

      // If an operation is not found for this log so we silently ignore it
      if (logOperation == null) {
        return;
      }

      const op = new Operation(oas?.api, logOperation.url.path, logOperation.url.method, logOperation.operation);
      // This function ensures the request's headers only includes those that are those present in the OAS
      const nextLog = sortHeaders(log, op);
      const { createdAt, group } = log;

      setSelectedHar({ id, isMock: Boolean(nextLog.isMock), createdAt, group, sharedMetric, ...nextLog.request });
    },
    // We don't want to do a reference comparison for oas, we only care if dereferencing has finished or the
    // internal `id` has changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOasDereferencing, oas?.api?._id],
  );

  const { clearMockHars, shouldUseMockHars } = useContext(MockHarContext);

  const Params = useMemo(() => {
    // This needs to be memoized! If it isn't we'll recreate the Params component everytime a state change happens,
    // which will cause the user to lose focus on their input.
    return createSchema(oas, operation);
    // We don't want to do a reference comparison for oas, we only care if the `id` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oas?.api?._id, operation]);

  const { inputRef, onAuthError } = useAuthInputs();

  useEffect(() => {
    // Reset the HAR file in state when a new operation is selected.
    clearMockHars();
    clearEphemeralHAR();
  }, [operation]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A normalized URL that we'll pass through to our Metrics service
   * This URL allows us to filter API logs for a specific endpoint/resource
   *
   * Returns a value similar to https://{region}.server.com/path/${resource}
   * In metrics, we'll use this value to query our database with wildcards via
   * fuzzy matching in place of each {curly brace} value.
   *
   * @todo  Since `GraphContainer`, `TableContainer` and `APIHeader` all use this same data maybe it should be in a context?
   */

  const metricsFilterUrl = useMemo(() => {
    if (operation === null || !isEndpoint) {
      return null;
    }

    return `${oas.url(servers.selected, {})}${operation.path}`;
    // We don't want to do a reference comparison for oas, we only care if the `id` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEndpoint, oas?.api?._id, servers, operation]);

  const preparedQuickNavData = useMemo(() => {
    try {
      return transformSidebarDataForQuickNav(sidebar);
    } catch {
      return null;
    }
  }, [sidebar]);

  // Are try-it-now requests enabled in the explorer via OAS
  const requestsEnabled = useMemo(
    () => oas?.getExtension(EXPLORER_ENABLED, operation) || false,
    // We don't want to do a reference comparison for oas, we only care if the `id` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oas.api?._id, operation],
  );

  // If they've disabled try-it-now in the explorer, but they've got the module enabled
  // We should check to see if they're actually using it
  const { data: usageResponse } = useMetricsAPI('requests/usage', !requestsEnabled);

  useEffect(() => {
    // We should ever show logs on an operation if Metrics is explicitly or implicitly enabled on
    // it and if interactivity is enabled or it's clear that Metrics is in use from usage reports.
    if (!oas.getExtension(METRICS_ENABLED, operation)) {
      setShowLogs(false);
      return;
    }

    setShowLogs(requestsEnabled || parseInt(usageResponse?.sdk?.thirtyDay, 10) > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oas?.api?._id, operation, requestsEnabled, usageResponse]);

  const header = useMemo(
    () => (
      <div className={classes['Main-QuickNav-container']}>
        <QuickNav destinations={preparedQuickNavData} modalTarget={'#QuickNav-modal-root'} />
      </div>
    ),
    [preparedQuickNavData],
  );

  const { user } = useContext(UserContext) || {};
  const { isLoggedIn } = useUserPermissions();

  // Custom icon render for My Requests page in sidebar list
  const customIconRender = useCallback(
    page => {
      const isYourRequests = page?.pageType === 'RealtimePage' && page?.title === 'My Requests';

      // "My Requests" page in sidebar has special icon handling
      return isLoggedIn && isYourRequests ? <UserAvatar /> : null;
    },
    [isLoggedIn],
  );

  /**
   * Error Boundry
   */
  if (dereferencingError) {
    // We're passing in `error.message` into the error boundary because it expects a child node to be present. It's fine
    // though as since we're also passing an `error` prop we'll default to our standard error handling instead of
    // rendering out that error message.
    return (
      <ErrorBoundary
        appContext="explorer"
        error={dereferencingError}
        maskErrorMessages={props.maskErrorMessages}
        onError={onError}
      >
        {dereferencingError.message}
      </ErrorBoundary>
    );
  } else if (operation === null && isEndpoint) {
    if (loading) {
      // Don't return an error before the route has actually loaded!
      return <main className="loading" id="Reference" />;
    }

    return (
      <ErrorBoundary
        appContext="explorer"
        error={new Error('Operation unable to be located within the supplied OAS.')}
        maskErrorMessages={props.maskErrorMessages}
        onError={onError}
      >
        We’re sorry, we weren’t able to render the docs for this API operation.
      </ErrorBoundary>
    );
  }

  // If we have a doc available to redirect to, that's our first option
  // otherwise we want to fallback to any provided redirectPath
  const RefRedirect = () => {
    if (doc.slug && redirectedPath?.includes('/reference')) {
      return (
        <Redirect
          to={
            location.pathname === '/reference'
              ? `reference/${doc.pageType === 'RealtimePage' ? 'intro/' : ''}${doc.slug}`
              : `${doc.slug}`
          }
        />
      );
    } else if (redirectedPath) {
      return <Redirect to={redirectedPath} />;
    }

    return null;
  };

  return (
    <ErrorBoundary appContext="explorer" maskErrorMessages={props.maskErrorMessages} onError={onError}>
      <InitializeReferenceStore
        apiDefinition={oas}
        maxLanguages={!!doc && doc.pageType === 'RealtimePage' ? 7 : 5}
        operation={operation}
      >
        <main
          className={`rm-ReferenceMain rm-Container rm-Container_flex ${isEndpoint ? '' : 'rm-basic-page'} ${
            loading || isOasDereferencing ? 'loading' : ''
          }`}
          id="Explorer"
        >
          {!!redirected && <RefRedirect />}
          <Sidebar
            activeDoc={doc?.slug || undefined}
            categories={sidebar}
            customIconRender={customIconRender}
            header={!!preparedQuickNavData && header}
            id="reference-sidebar"
            isLoggedIn={isLoggedIn}
            pathRoot="reference"
          />
          <Switch>
            <Route path={`${path}/intro/:slug(getting-started|authentication|my-requests)`}>
              <Realtime
                doc={doc}
                hasOasDefinition={!!oasDefinition}
                inputRef={inputRef}
                myRequestsEnabled={myRequestsEnabled}
                oas={oas}
                oasPublicUrl={oasPublicUrl}
                oauth={oauth}
                onError={onAuthError}
                operation={operation}
                operationParams={
                  <Params
                    alwaysUseDefaults={alwaysUseDefaults}
                    globalDefaults={user?.parameters}
                    oas={oas}
                    operation={operation}
                  />
                }
                path={path}
                requestsEnabled={requestsEnabled}
                servers={servers}
                sidebar={sidebar}
              />
            </Route>
            <Route path={`${path}/:slug?`}>
              <article className="rm-Article" id="content">
                {!!doc && (
                  <Header
                    doc={doc}
                    isWebhook={isWebhook}
                    oas={oas}
                    operation={operation}
                    servers={servers}
                    sidebar={sidebar}
                  />
                )}
                {!!doc && !!doc.tutorials && doc.tutorials.length > 0 && <RecipeContainer tutorials={doc.tutorials} />}
                {!!showMetricsInReference && !!isEndpoint && !!showLogs && (
                  <ErrorBoundary appContext="explorer" maskErrorMessages={props.maskErrorMessages} onError={onError}>
                    <div className="Reference-section">
                      <SectionHeader
                        heading={
                          <Flex align="center" gap="xs" justify="start">
                            {!isLoggedIn ? (
                              <>
                                <Icon aria-label="Key" name="key" />
                                Log in to see full request history
                              </>
                            ) : (
                              'Recent Requests'
                            )}
                          </Flex>
                        }
                      />

                      <TableContainer
                        ephemeralHAR={ephemeralHAR}
                        footer={
                          !shouldUseMockHars && (
                            <GraphContainer
                              ephemeralHAR={ephemeralHAR}
                              isLoggedIn={isLoggedIn}
                              method={operation.method}
                              myRequestsEnabled={myRequestsEnabled}
                              url={metricsFilterUrl}
                            />
                          )
                        }
                        onSelectLog={handleSelectLog}
                        operation={operation}
                        url={metricsFilterUrl}
                      />
                    </div>
                  </ErrorBoundary>
                )}

                {!!doc?.body && (
                  <RDMD
                    key={doc.slug}
                    body={doc.body}
                    className="content-body"
                    mdx={project.flags.mdx}
                    opts={rdmdOpts}
                  />
                )}

                {(!!isEndpoint || !!isWebhook) && (
                  <Params
                    alwaysUseDefaults={alwaysUseDefaults}
                    globalDefaults={user?.parameters}
                    isWebhook={isWebhook}
                    oas={oas}
                    // onSubmit={() => {}} // @todo This should be wired up into the same executor as clicking the "Try It" button.
                    operation={operation}
                  />
                )}

                {(!!isEndpoint || !!isWebhook) && (
                  <>
                    <ResponseSchemaPicker
                      defaultExpandResponseSchema={defaultExpandResponseSchema}
                      oas={oas}
                      operation={operation}
                    />
                    <div className="ModalWrapper" id="response-schema-modal-target" />
                  </>
                )}
                {!!operation?.hasCallbacks() && (
                  <>
                    <Callbacks oas={oas} operation={operation} />
                    <div className="ModalWrapper" id="callback-response-schema-modal-target" />
                  </>
                )}
                {!(loading || isOasDereferencing) && (
                  <div className={classes['Footer-desktop']}>
                    <Footer doc={doc} project={project} version={version} />
                  </div>
                )}
              </article>
              {(!!isEndpoint || !!isWebhook) && (
                <Playground id="ReferencePlayground">
                  {!!isEndpoint && (
                    <>
                      <PlaygroundSection heading="Language">
                        <LanguagePicker />
                      </PlaygroundSection>
                      <PlaygroundSection>
                        <AuthContainer
                          apiDefinition={oas}
                          customLoginEnabled={!!oauthUrl}
                          inputRef={inputRef}
                          oauth={oauth}
                          operation={operation}
                          setSelectedHar={setSelectedHar}
                        />
                      </PlaygroundSection>
                      {/*
                      Only show the servers component if one of these conditions are true:
                      - there is more than one server in the servers[] array
                      - the server URL has a variable (so needs editing)
                      @todo this should probably be refactored into Oas.hasEditableServer() or something
                    */}
                      {((oas?.api?.servers || [{ url: 'https://example.com' }]).length > 1 ||
                        oas.splitUrl(servers.selected).filter(({ type }) => type === 'variable').length > 0) && (
                        <PlaygroundSection heading="URL">
                          <ServerContainer oas={oas} operation={operation} />
                        </PlaygroundSection>
                      )}
                    </>
                  )}
                  <PlaygroundSection sticky>
                    <RequestContainer
                      allowApiExplorerJsonEditor={allowApiExplorerJsonEditor}
                      apiDefinition={oas}
                      har={selectedHar}
                      isWebhook={isWebhook}
                      onError={onAuthError}
                      operation={operation}
                      requestsEnabled={requestsEnabled}
                      setResponseHAR={setEphemeralHAR}
                      url={oasPublicUrl}
                    />
                    {!!isEndpoint && (
                      <Response
                        apiDefinition={oas}
                        defaultExpandResponseExample={defaultExpandResponseExample}
                        har={selectedHar || ephemeralHAR || null}
                        onExampleRemove={clearEphemeralHAR}
                        operation={operation}
                        requestsEnabled={requestsEnabled}
                        setSelectedHar={setSelectedHar}
                      />
                    )}
                    {/* temporarily for demo purposes */}
                    {['greg@readme.io', 'tony@readme.io'].includes(user?.email || '') && (
                      <Flex align="center" className={classes.TempBanner} gap="md" justify="start" tag="aside">
                        <img alt="VSCode logo" height="30" src={vscodelogo} width="30" />
                        <Flex gap="xs" layout="col">
                          Ask questions about ReadMe’s API in your editor
                          <Button
                            circular
                            href="https://github.com/login/oauth/authorize?client_id=Iv1.0321906479c93023"
                            rel="noreferrer"
                            size="xs"
                            target="_blank"
                          >
                            Install in VSCode
                            <Icon name="arrow-up-right" />
                          </Button>
                        </Flex>
                      </Flex>
                    )}
                  </PlaygroundSection>
                </Playground>
              )}

              {!(loading || isOasDereferencing) && (
                <div className={classes['Footer-mobile']}>
                  <Footer doc={doc} project={project} version={version} />
                </div>
              )}
              {!!showTableOfContents && doc?.type === 'basic' && (
                <section className="content-toc grid-25">
                  <TOC body={doc?.body} mdx={project.flags.mdx} opts={rdmdOpts} />
                </section>
              )}
            </Route>
          </Switch>

          {/**
           * Response headers modal root lives outside <Response/> component to escape z-index trapping from
           * a position:sticky; wrapper in the parent <PlaygroundSection/> component
           */}
          <div className="ModalWrapper" id="response-headers-modal-root" />

          <div className="ModalWrapper" id="tutorialmodal-root"></div>
          <div className="ModalWrapper QuickNav-modal QuickNav-modal-desktop" id="QuickNav-modal-root" />
          <div className="ModalWrapper QuickNav-modal QuickNav-modal-mobile" id="QuickNav-mobile-modal-root" />
        </main>
        {!!isPlayground && <PreviewBanner />}
      </InitializeReferenceStore>
    </ErrorBoundary>
  );
}

Reference.propTypes = {
  customBlocks: PropTypes.shape({
    tags: PropTypes.object,
  }),
  doc: PropTypes.object,
  hideTOC: PropTypes.bool,
  loginUrl: PropTypes.string,
  maskErrorMessages: PropTypes.bool,
  oasDefinition: PropTypes.shape({}),
  oasPublicUrl: PropTypes.string,
  /**
   * If `legacy-enabled`, the project contains an OAuth URL, and an "Authorize" button should be displayed above the
   * OAuth input.
   *
   * If `enabled`, the OAuth flows UI should be displayed.
   *
   * If `false`, no OAuth UI should be displayed.
   */
  oauth: PropTypes.oneOf(['enabled', 'legacy-enabled', false]),
  rdmd: PropTypes.object.isRequired,
  reqUrl: PropTypes.string,
  sidebars: PropTypes.shape({
    docs: PropTypes.array.isRequired,
    refs: PropTypes.array.isRequired,
  }).isRequired,

  /**
   * A hook to override the internal `useRouteData` hook with a reference testbed hook that dynamically retrieves
   * operations from the currently selected testbed API definition.
   *
   * @see `@routes/Reference/TestBed`
   */
  useTestBedState: PropTypes.func,

  version: PropTypes.shape({
    version: PropTypes.string,
  }),
};

Reference.defaultProps = {
  maskErrorMessages: true,
  oauth: false,
  useTestBedState: p => [p],
};
