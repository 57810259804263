import cloneDeep from 'lodash/cloneDeep'; // eslint-disable-line you-dont-need-lodash-underscore/clone-deep
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { BaseUrlContext } from '@core/context';

import TutorialModal from '@routes/Tutorials/components/Modal';
import { DEFAULT_TUTORIAL } from '@routes/Tutorials/components/Modal/constants/stepDefaults';
import TutorialTile from '@routes/Tutorials/components/Tile';

import SectionHeader from '@ui/API/SectionHeader';

import classes from './style.module.scss';

export default function RecipeContainer({ tutorials }) {
  const baseUrl = useContext(BaseUrlContext);
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState(DEFAULT_TUTORIAL);

  const openTutorialModal = ({ tutorial }) => {
    setSelectedTutorial({ ...cloneDeep(tutorial) });
    setShowTutorialModal(true);
  };

  const closeTutorialModal = () => {
    setSelectedTutorial(DEFAULT_TUTORIAL);
    setShowTutorialModal(false);
  };

  return (
    <div className="Reference-section">
      <SectionHeader heading="Recipes" />
      <div className={classes.RecipeContainer}>
        {tutorials.map((tutorial, i) => (
          <TutorialTile
            key={`tutorialtile-${i}`}
            className={classes['RecipeContainer-item']}
            openTutorial={openTutorialModal}
            tutorial={tutorial}
          />
        ))}
      </div>
      <TutorialModal
        action={'View'}
        baseUrl={baseUrl.replace(/\/$/, '')}
        closeTutorialModal={closeTutorialModal}
        moduleEnabled={true}
        open={showTutorialModal}
        target={'#tutorialmodal-root'}
        tutorial={selectedTutorial}
      />
    </div>
  );
}

RecipeContainer.propTypes = {
  tutorials: PropTypes.array.isRequired,
};
